.about-container {
    background-color: lightblue;
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  
  .about-heading {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 20mm;
  }
  
  .about-flex {
    margin-top: 32px;
    width: 70%;  /* Ensure flex container does not overflow */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .about-flex {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }
  }
  
  .about-text {
    flex: 3;
    width: 100%;  /* Ensure text container does not overflow */
  }
  
  .about-image-container {
    flex: 2;
    text-align: center;
    margin-left: 0;
    position: relative;
    z-index: 1000;
    width: 100%;  /* Ensure image container does not overflow */
  }
  
  @media (min-width: 768px) {
    .about-image-container {
      margin-left: 32px;
    }
  }
  
  .about-image {
    width: 100%;
    max-width: 250px;  /* Ensure image does not overflow */
    min-width: 70%;
    height: auto;
    border-radius: 200px;
  }
  

/* DISAPPEARING TEXT STYLING */
.disappearing-text-container {
    position: absolute;
    top: 17%;
    max-width: 100vw;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    overflow: hidden;
}

.disappearing-text {
    font-size: 1em;
    color: white;
    font-family: 'Impact, fantasy';
}
