@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');



/* base styles */
body {
  background-color: lightgray;
}

* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 100vw;
  margin: 0 auto;
  border-bottom: 1px solid #000000;
  background-color: #7CB9E8;
}
.navbar h1 {
  color: #36454F; 
}
.navbar .brand:hover h1 {
  color: #f1356d; /* Color on hover */
}
.navbar .links {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.large-font {
  font-size: 1.5em;
  padding: 50px;
  text-align: center;
}

.footer {
  /* position: 'fixed'; */
  bottom:0;
  z-index:1000;
  padding: 15px;
  display: flex;
  align-items: center;
  max-width: 100vw;
  margin: 0 auto;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  background-color: #7CB9E8;
}
.footer .links {
  margin-left: auto;
}
.footer a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.footer h1 {
  font-size: 1.1em;
  color: #36454F; 
}
body {
  overflow-x: hidden;
}

/* FirstmonthCornell Blog  */
.small-footer-text p {
  font-size: 7px; /* You can adjust the size as needed */
}
