/* FOR YOUTUBE HOVER EFFECT */  

.image-container {
    position: relative;
    display: inline-block;
}

.image {
    display: block;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
    opacity: 1;
}

.youtube-logo {
    width: 50px; /* Adjust size as needed */
    height: auto;
}
